import { Checkbox, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { intersection } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryCache } from 'react-query'
import { NavLink } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { EducationModule } from '@/api/education-modules/modules'
import { useGroups } from '@/api/groups/groups'
import { AllMembersQueryVariables, Member, useMembers } from '@/api/members/get'
import { useOffices } from '@/api/offices/offices'
import { User } from '@/api/users/users'
import inactiveMembersAnimation from '@/assets/lottie/melting.json'
import { Targets } from '@/types/campaigns'
import { PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '../../utils/table-size'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import LoadingContainer from '../LoadingContainer/LoadingContainer'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import PaginatingTable from '../Tables/PaginatingTable'
import Tags from '../Tags/Tags'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

type LaunchWizardUsersTableProps = {
  handleAddUsers: (userIds: string[], results: User[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  selectedUsers: string[]
  selectedGroups: string[]
  selectedOffices: string[]
  selectedDynamicGroupsMembers: string[]
  clientEnforceDomain: boolean
}

const INITIAL_QUERY_FILTERS = {
  search: '',
  offices: [],
  groups: [],
  status: ['active'],
  limit: 5,
  skip: 0,
}

const LaunchWizardUsersTable: FC<LaunchWizardUsersTableProps> = ({
  handleAddUsers,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  selectedUsers,
  selectedGroups,
  selectedOffices,
  selectedDynamicGroupsMembers,
  clientEnforceDomain,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const queryCache = useQueryCache()
  const { data: offices } = useOffices()
  const { data: groups } = useGroups()
  const { data: members } = useMembers()
  const [queryFilters, setQueryFilters] = useState<AllMembersQueryVariables>(INITIAL_QUERY_FILTERS)
  const { data: queryData, isLoading } = useMembers(queryFilters)
  const { results: results, total } = queryData ?? { total: 0, items: [] }
  const [pageCount, setPageCount] = useState(0)
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE)
  const isPackage = assets && assets.length > 1
  const isVideoCampaign = assets?.every((asset) => 'video_tracks' in asset && asset.video_tracks)

  const filteredResults = useMemo(() => {
    return results?.map((member) => {
      const memberDomainAllowed = !(clientEnforceDomain && !member.domain_verified)
      const onlyAssetsWithEmail =
        assets?.filter((a) => a.vectors?.includes(PhishingSimulationVector.Email)).length === assets?.length

      return {
        ...member,
        disabled:
          member.state === 'inactive' || !memberDomainAllowed || (onlyAssetsWithEmail && !member.domain_verified),
      }
    })
  }, [results, assets, clientEnforceDomain, isVideoCampaign])

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE, pageSize)
  }

  const groupsById = useMemo(() => {
    const byId = {}
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }
    return byId
  }, [groups])

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        skip: prevState.limit * pageIndex,
        limit: pageSize,
      }))
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(total / pageSize))
      queryCache.prefetchQuery([
        'results',
        {
          ...queryFilters,
          skip: pageSize * (pageIndex + 1),
          limit: pageSize,
        },
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [total]
  )

  const columns: ColumnInterface[] = useMemo(() => {
    const columns = [
      {
        Header: () => {
          const userIds = filteredResults?.map((group) => group._id)
          function handleChange(e) {
            if (e.target.checked) {
              handleAddUsers(userIds, filteredResults)
            } else {
              handleRemoveFromList(userIds)
            }
          }
          const checked = intersection(selectedUsers, userIds).length === filteredResults?.length

          const isAnyUserDisabled = filteredResults?.some((user) => user.disabled)

          return (
            <Tooltip
              title={t('launchWizard.usersTable.tooltips.containsWarnings')}
              placement={'right'}
              disableHoverListener={!isAnyUserDisabled}>
              <div>
                <Checkbox onChange={handleChange} checked={checked} disabled={isAnyUserDisabled} />
              </div>
            </Tooltip>
          )
        },
        accessor: 'selection',
        Cell: ({ row }) => {
          function handleChange(e) {
            if (e.target.checked) {
              handleAddUsers([row.original._id], [row.original])
            } else {
              handleRemoveFromList(row.original._id)
            }
          }

          const checked =
            selectedUsers.includes(row.original._id) ||
            intersection(row.original.member_of, selectedGroups).length > 0 ||
            selectedOffices.includes(row.original.office_id) ||
            selectedDynamicGroupsMembers.includes(row.original._id)

          const member = row.original
          return (
            <Tooltip
              title={<TableBodyTooltip member={member} />}
              placement={'right'}
              disableHoverListener={!row.original.disabled}>
              <div>
                <Checkbox checked={checked} onChange={handleChange} disabled={row.original.disabled} />
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data) => {
          const { first_name, last_name, username } = data.row.original
          return (
            <Tooltip
              title={username}
              placement="bottom-start"
              PopperProps={{
                modifiers: [{ name: 'offset', options: { offset: [-4, -12] } }],
              }}>
              <Typography>{[first_name, last_name].join(' ')}</Typography>
            </Tooltip>
          )
        },
      },
      {
        Header: t('users.usersTable.office'),
        accessor: 'office_id',
        disableSortBy: true,
        Cell: ({ value }) => {
          return offices?.byId[value] ? offices.byId[value]?.name : ''
        },
      },
      {
        Header: t('users.usersTable.groups'),
        accessor: 'member_of',
        disableSortBy: true,
        Cell: ({ value }) =>
          value ? (
            <div className={classes.tags}>
              <Tags
                useChips={false}
                tags={value.map((groupId) => ({
                  label: groupsById[groupId]?.name,
                }))}
                limit={1}
              />
            </div>
          ) : null,
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
    ]

    if (isPackage) {
      columns.splice(4, 0, {
        Header: t('users.usersTable.distribution'),
        accessor: 'distribution',
        Cell: ({ row }) => (
          <DistributionSelect
            type="users"
            onChange={handleDistributionTargetsChange}
            itemId={row.original._id}
            selectedItems={distributionTargets.users}
            assets={assets}
            disabled={row.original.disabled}
            disableUnverifiedDomain={!row.original.domain_verified}
          />
        ),
      })
    }

    return columns
  }, [t, offices, selectedUsers, groupsById, filteredResults])

  const handleSearchChange = useCallback((value: string) => {
    setQueryFilters((prevState) => ({
      ...prevState,
      search: value,
    }))
  }, [])

  const handleStatusChange = (newStatus: string[]) => {
    setQueryFilters({
      ...queryFilters,
      status: newStatus,
    })
  }

  return !isLoading ? (
    <>
      <div style={{ marginBottom: theme.spacing(1), display: 'flex' }}>
        <SimpleSearchBar onChange={handleSearchChange} debounceTime={0} />
        <StatusFilter onChange={handleStatusChange} values={queryFilters.status} label="Status" />
      </div>{' '}
      <Paper className={classes.root}>
        <PaginatingTable
          noResultsContent={<NoResults activeMembers={members?.total} totalMembers={total} />}
          columns={columns}
          data={filteredResults ?? []}
          fetchData={fetchData}
          loading={isLoading}
          pageCount={pageCount}
          count={total}
          enableCheckbox={false}
          customCheckbox={true}
          initialPageSize={defaultRowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  ) : (
    <LoadingContainer />
  )
}

type NoResultsProps = {
  activeMembers: number | undefined
  totalMembers: number
}

const NoResults = ({ activeMembers, totalMembers }: NoResultsProps) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={activeMembers || 0 > totalMembers ? inactiveMembersAnimation : undefined}>
      <p>{t(activeMembers || 0 > totalMembers ? 'noResults.imactiveMembers' : 'noResults.noRecipiants')}</p>
      <p>
        <Trans
          i18nKey={activeMembers || 0 > totalMembers ? 'noResults.goToMembers' : 'noResults.goToRecipiants'}
          components={{ 1: <NavLink to={'/recipients/members'} />, 2: <NavLink to={'/settings/admins'} /> }}
        />
      </p>
    </InTableMessageWithAnimation>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'center',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
      },
    },
    tags: {
      display: 'flex',
      justifyContent: 'center',
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)
const TableBodyTooltip: React.FC<{ member: Member }> = ({ member }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          member.state === 'inactive'
            ? 'launchWizard.usersTable.tooltips.inactiveMembers.description'
            : 'launchWizard.usersTable.tooltips.unverifiedDomains.description'
        }
        components={{
          1: (
            <Link
              href={member.state === 'inactive' ? '../../recipients/members' : '../../settings/allowlisting'}
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardUsersTable
