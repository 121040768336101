import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { filter, intersection } from 'lodash'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { useDynamicGroups } from '@/api/dynamic-groups/get'
import { EducationModule } from '@/api/education-modules/modules'
import { Group, useGroups } from '@/api/groups/groups'
import noGroupsAnimation from '@/assets/lottie/suspicion.json'
import { Targets } from '@/types/campaigns'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '../../utils/table-size'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import SimpleTable from '../Tables/SimpleTable'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

type LaunchWizardGroupsTableProps = {
  handleAddGroups: (groupIds: string[], groups: Group[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  selectedGroups: string[]
  clientEnforceDomain: boolean
}

const INITIAL_QUERY_FILTERS = {
  name: '',
  status: ['active'],
}

const LaunchWizardGroupsTable: FC<LaunchWizardGroupsTableProps> = ({
  handleAddGroups,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  selectedGroups,
  clientEnforceDomain,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: groups = [] } = useGroups()
  useDynamicGroups({ params: { check_unverified_domain: true } }) // Call this to preload data
  const [queryFilters, setQueryFilters] = useState(INITIAL_QUERY_FILTERS)
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE)
  const isPackage = assets && assets.length > 1

  const handleFilterChange = useCallback((value: string) => {
    setQueryFilters((prevState) => ({ ...prevState, name: value }))
  }, [])

  const handleStatusChange = useCallback((value: string[]) => {
    setQueryFilters((prevState) => ({ ...prevState, status: value }))
  }, [])

  const data = useMemo(() => {
    groups.forEach((group) => {
      if (group.user_count === 0) {
        group.disabled = true
      }
    })

    return filter(groups, (group: Group) => {
      let matchesName = true
      let matchesStatus = true

      if (queryFilters.name) {
        matchesName = group.name.toLowerCase().includes(queryFilters.name.toLowerCase())
      }

      if (queryFilters.status.length > 0) {
        const isActive = group.has_inactive_member === false
        if (queryFilters.status.includes('active') && isActive) {
          matchesStatus = true
        } else if (queryFilters.status.includes('inactive') && !isActive) {
          matchesStatus = true
        } else {
          matchesStatus = false
        }
      }
      return matchesName && matchesStatus
    })
  }, [groups, queryFilters])

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE, pageSize)
  }

  const columns: ColumnInterface[] = React.useMemo(() => {
    const columns = [
      {
        id: 'selection',
        Header: () => {
          const groupIds = data.map((group: Group) => group._id)
          function handleChange(e) {
            if (e.target.checked) {
              handleAddGroups(groupIds, data)
            } else {
              handleRemoveFromList(groupIds)
            }
          }
          const checked = !!groups.length && intersection(selectedGroups, groupIds).length === groups.length

          const isAnyMemberInactive = data.some((group: Group) => group.has_inactive_member)

          const isAnyUnverifiedDomain = data.some((group: Group) => group.has_unverified_domain && clientEnforceDomain)

          return (
            <Tooltip
              title={t('launchWizard.groupsTable.tooltips.containsWarnings')}
              placement={'right'}
              disableHoverListener={!isAnyMemberInactive && !isAnyUnverifiedDomain}>
              <div>
                <Checkbox onChange={handleChange} checked={checked} />
              </div>
            </Tooltip>
          )
        },
        Cell: ({ row }) => {
          function handleChange(e) {
            if (e.target.checked) {
              handleAddGroups([row.original._id], [row.original])
            } else {
              handleRemoveFromList(row.original._id)
            }
          }

          const group = row.original

          const isDisabled = group.disabled
          const checked = selectedGroups.includes(row.original._id)

          return (
            <Tooltip title={<TableBodyTooltip group={group} />} placement={'right'} disableHoverListener={!isDisabled}>
              <div>
                <Checkbox checked={checked} onChange={handleChange} disabled={isDisabled} />
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: t('users.groupsTable.group'),
        accessor: 'name',
        Cell: ({ value, row }) => {
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))
          const hasWarning =
            (hasEmailSimulation && row.original.has_unverified_domain) || row.original.has_inactive_member
          const group = row.original
          return (
            <div>
              <span>{value}</span>&nbsp;
              {hasWarning ? (
                <Tooltip
                  title={<TableBodyTooltip group={group} />}
                  placement={'right'}
                  disableHoverListener={!hasWarning}>
                  <FontAwesomeIcon icon={faTriangleExclamation as IconProp} className={classes.warningIcon} />
                </Tooltip>
              ) : null}
            </div>
          )
        },
      },
      {
        Header: t('users.groupsTable.userCount'),
        accessor: 'user_count',
        Cell: ({ value }) => value,
      },
      {
        Header: t('users.groupsTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
    ]

    if (isPackage) {
      columns.splice(3, 0, {
        Header: t('users.groupsTable.distribution'),
        accessor: 'distribution',
        Cell: ({ row }) => (
          <DistributionSelect
            type="groups"
            onChange={handleDistributionTargetsChange}
            itemId={row.original._id}
            selectedItems={distributionTargets.groups}
            assets={assets}
            disabled={row.original.disabled}
            disableUnverifiedDomain={row.original.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedGroups, data])

  return (
    <>
      <div style={{ marginBottom: theme.spacing(1), display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} debounceTime={0} />
        <StatusFilter onChange={handleStatusChange} values={queryFilters.status} label="Status" />
      </div>
      <Paper className={classes.root}>
        <SimpleTable
          columns={columns}
          data={data}
          setData={() => {}}
          noResultsContent={<NoResults />}
          enableCheckbox={false}
          customCheckbox={true}
          initialPageSize={defaultRowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

const NoResults: FC = () => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={noGroupsAnimation}>
      <p>{t('noResults.emptyGroup')}</p>
      <p>
        <Trans i18nKey={'noResults.goToGroup'} components={{ 1: <NavLink to="/recipients/groups" /> }} />
      </p>
    </InTableMessageWithAnimation>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
      },
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    warningIcon: {
      color: '#F5A623',
      height: 18,
      margin: theme.spacing(0, 0.5),
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

const TableBodyTooltip: FC<{ group: Group }> = ({ group }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          group.has_inactive_member
            ? 'launchWizard.groupsTable.tooltips.inactiveMembers.description'
            : group.has_unverified_domain
            ? 'launchWizard.groupsTable.tooltips.unverifiedDomains.description'
            : group.user_count === 0
            ? 'launchWizard.groupsTable.tooltips.noMembers.description'
            : ''
        }
        components={{
          1: (
            <Link
              href={
                group.has_inactive_member
                  ? '../../recipients/members'
                  : group.has_unverified_domain
                  ? '../../settings/allowlisting'
                  : group.user_count === 0
                  ? '../../recipients/groups'
                  : ''
              }
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardGroupsTable
