import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faHouse } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useMemo } from 'react'

import { useOrganizations } from '@/api/organizations/get'
import CywarenessLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import CywarenessBackground from '@/assets/images/logo-light-blue.svg'
import TreeChart from '@/common/components/AccountCenter/TreeChart'
import { OrganizationListItem } from '@/types/organizations'
import { useTranslation } from 'react-i18next'

export type OrganizationCardData = OrganizationListItem & {
  id: string
  parentId: string
  _highlighted: boolean
  _expanded: boolean
}

const AccountCenter: FC = () => {
  const classes = useStyles()
  const { data } = useOrganizations()
  const { t } = useTranslation()

  //TODO Add fake root node
  const treeChartData: OrganizationCardData[] = useMemo(() => {
    if (!data?.length) return []

    const fakeRoot = {
      id: 'fake_root',
      name: 'Account Center',
      parentId: null,
    }

    const organizations = data.map((organization) => ({
      ...organization,
      id: organization._id,
      parentId: organization.parent_id === null ? 'fake_root' : organization.parent_id,
    }))

    return [fakeRoot, ...organizations]
  }, [data])

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box>
          <img src={CywarenessLogo} alt="Cywareness Logo" />
        </Box>
        <Box>
          <Typography className={classes.title}>{t('accountCenter.accountCenter')}</Typography>
        </Box>
        <Box textAlign="right">
          <Button
            onClick={() => {
              window.location.href = '/'
            }}
            endIcon={<FontAwesomeIcon icon={faHouse as IconProp} />}
          >
            {t('accountCenter.backToDashboard')}
          </Button>
        </Box>
      </Box>
      <TreeChart data={treeChartData} />
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${CywarenessBackground})`,
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
    },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: '20px',
      '&>*': {
        flex: 1,
      },
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 36,
      fontWeight: 700,
      textAlign: 'center',
    },
  })
)

export default AccountCenter
