import { theme } from '@/theme/theme'
import { Autocomplete, Box, TextField } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizations } from '@/api/organizations/get'

import { OrganizationListItem } from '@/types/organizations'

type TreeChartSearchProps = {
  searchValue: OrganizationListItem | null
  setSearchValue: (value: OrganizationListItem | null) => void
  handleSearch: (name: OrganizationListItem | null) => void
  handleClose: () => void
}

const TreeChartSearch: FC<TreeChartSearchProps> = ({ searchValue, setSearchValue, handleSearch, handleClose }) => {
  const { t } = useTranslation()
  const { data } = useOrganizations()
  const classes = useStyles()
  const handleInputChange = (_: any, newInputValue: string) => {
    if (!newInputValue) {
      handleClose()
    }
  }

  return (
    <Box className={classes.root}>
      <Autocomplete
        value={searchValue}
        onChange={(_, newValue) => {
          setSearchValue(newValue as OrganizationListItem | null)
          handleSearch(newValue as OrganizationListItem | null)
        }}
        onInputChange={handleInputChange}
        freeSolo
        options={(data || []).sort((a, b) => a.name.localeCompare(b.name))}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
        renderOption={(props, option) => (
          <li {...props} key={option._id} className={classes.option}>
            {typeof option === 'string' ? option : option.name || ''}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label={t('accountCenter.actions.searchPlaceholder')}
            InputLabelProps={{ children: (params.InputLabelProps?.children as React.ReactNode) || null }}
          />
        )}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        clearOnEscape
        filterOptions={(options, state) =>
          options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
        }
      />
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      left: '110%',
      width: '192px',
      borderRadius: '16px',
      backgroundColor: theme.palette.white,
    },
    option: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      '&:hover': {
        backgroundColor: theme.palette.blueDianne[50],
      },
    },
  })
)

export default TreeChartSearch
