import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBlockQuestion,
  faGauge,
  faHexagon,
  faInfoCircle,
  faSwords,
  faWavePulse,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, InputAdornment, styled, TextField, Tooltip, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCampaignAssets } from '@/api/campaigns/campaign-assets'
import { useEditCampaign } from '@/api/campaigns/edit'
import { useCampaign } from '@/api/campaigns/get'
import { useCampaignUsers } from '@/api/campaigns/getUsers'
import { EU_DATE_TIME_12HR, ISO_DATE_TIME } from '@/api/constants'
import { useEducationModuleById } from '@/api/education-modules/module-by-id'
import { getErrorMessage } from '@/api/utils/get-error'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { AssetType, CampaignStatus } from '@/types/campaigns'
import LoadingButton from '@mui/lab/LoadingButton'
import LoadingContainer from '../LoadingContainer/LoadingContainer'
import PopupLarge, { ButtonsWrapper } from '../PopupLarge'

//TODO: Consider better place for this helper function
export const allowStartChange = (status: CampaignStatus, date: string) => {
  const diffInMinutes = dayjs(date).diff(dayjs(), 'minute')
  return status !== CampaignStatus.canceled && status !== CampaignStatus.error && diffInMinutes > 3
}

const allowEndChange = (status: CampaignStatus, date: string) => {
  const diffInMinutes = dayjs(date).diff(dayjs(), 'minute')
  return (status === CampaignStatus.in_progress || status === CampaignStatus.pending) && diffInMinutes > 3
}

type EditModalProps = {
  campaignId: string
  open: boolean
  onClose: () => void
}

const EditModal: FC<EditModalProps> = ({ campaignId, open, onClose }) => {
  const { t } = useTranslation()

  const [campaign, setCampaign] = useState({
    _id: campaignId,
    name: '',
    schedule: {
      start: '',
      end: '',
    },
  })

  const { successToast, errorToast } = useToast()

  const { data: campaignData, isPending: isCampaignPending } = useCampaign(campaignId)
  const { data: allUsersData, isPending: isUsersPending } = useCampaignUsers(
    { id: campaignId, limit: 0 },
    { refetchInterval: 10_000 }
  )
  const { data: simulationsData } = useCampaignAssets(campaignData?._id, {
    enabled: campaignData?.asset_type === AssetType.phishing_simulation,
  })

  const { data: asset, isLoading: isAssetPending } = useEducationModuleById(campaignData?.asset_ids[0], {
    enabled: campaignData?.asset_type === AssetType.training_video && campaignData?.asset_metadata?.include_quiz,
  })

  const { mutateAsync: updateCampaign, isPending } = useEditCampaign()

  const handleChangeDate = (date: any, key: string) => {
    setCampaign((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        [key]: dayjs(date).format(ISO_DATE_TIME),
      },
    }))
  }

  const handleUpdate = async () => {
    try {
      await updateCampaign(campaign)
      onClose()
      successToast(t('adminDashboard.campaignsTable.editCampaignSuccess'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('adminDashboard.campaignsTable.editCampaignError'))
    }
  }

  useEffect(() => {
    if (campaignData) {
      setCampaign({
        _id: campaignData._id,
        name: campaignData.name,
        schedule: {
          start: dayjs(campaignData.schedule.start).format(ISO_DATE_TIME),
          end: dayjs(campaignData.schedule.end).format(ISO_DATE_TIME),
        },
      })
    }
  }, [campaignData])

  const assetTypeText = useMemo(() => {
    if (Number(campaignData?.asset_ids?.length) > 1) {
      return 'package ' + campaignData?.asset_ids.length
    } else if (campaignData?.asset_metadata?.include_quiz) {
      return t('campaignDashboard.training_video_quiz')
    } else {
      return t('campaignDashboard.' + campaignData?.asset_type)
    }
  }, [campaignData, t, asset])

  const vectors = useMemo(
    () =>
      campaignData?.asset_metadata?.vector
        ? [campaignData.asset_metadata.vector]
        : [...new Set(simulationsData?.flatMap((simulation) => simulation.vectors) || [])] || ['video'],
    [campaignData, simulationsData]
  )

  const vectorText = vectors.map((vector) => t('library.' + vector)).join(', ')
  const hasMultipleVectors = vectors.length > 1
  const difficulties = [...new Set(simulationsData?.flatMap((sim) => sim.difficulties) || [])].join(', ')

  return (
    <PopupLarge open={open} onClose={onClose} title={t('adminDashboard.campaignsTable.editCampaignPopup.title')}>
      {isCampaignPending || isUsersPending || isAssetPending ? (
        <LoadingContainer />
      ) : (
        <>
          <StyledCampaignDetails>
            <DetailsBox
              icon={faWavePulse}
              label={t('trainingCampaignDashboard.status')}
              value={t('trainingCampaignDashboard.' + campaignData?.status)}
            />

            <StyledDetailsSteparator />
            <DetailsBox icon={faHexagon} label="Type" value={assetTypeText} />

            {asset?.quizzes && campaignData?.asset_metadata?.include_quiz && (
              <>
                <StyledDetailsSteparator />
                <DetailsBox
                  icon={faBlockQuestion}
                  label={t('trainingCampaignDashboard.quiz')}
                  value={(asset.quizzes[0].questions.length || 0) + ' ' + t('trainingCampaignDashboard.questions')}
                />
              </>
            )}

            {campaignData?.asset_type === AssetType.phishing_simulation && (
              <>
                <StyledDetailsSteparator />
                <DetailsBox icon={faGauge} label={t('campaignDashboard.difficulty')} value={difficulties} />
                <StyledDetailsSteparator />
                <DetailsBox
                  icon={faSwords}
                  label={t('campaignDashboard.attackVector')}
                  value={hasMultipleVectors ? t('campaignDashboard.mixedVectors') : vectorText}
                />
              </>
            )}

            <StyledDetailsSteparator />
            <DetailsBox
              icon={faWavePulse}
              label={t('trainingCampaignDashboard.totalMembers')}
              value={allUsersData?.total}
            />
          </StyledCampaignDetails>
          <TextField
            variant="outlined"
            label={t('launchWizard.details.name')}
            value={campaign.name}
            onChange={(event) => setCampaign((prev) => ({ ...prev, name: event.target.value }))}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <Tooltip title={t('adminDashboard.campaignsTable.editCampaignPopup.nameTooltip')} placement="top">
                  <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                    <FontAwesomeIcon icon={faInfoCircle as IconProp} />
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <Box display="flex" gap={4} mt={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                disabled={!allowStartChange(campaignData?.status as CampaignStatus, campaignData?.schedule?.start)}
                inputFormat={EU_DATE_TIME_12HR}
                label={t('launchWizard.details.startDate')}
                value={dayjs(campaign?.schedule.start).toDate()}
                minDateTime={
                  allowStartChange(campaignData?.status as CampaignStatus, campaignData?.schedule?.start) &&
                  dayjs().add(1, 'minutes')
                }
                onChange={(date) => handleChangeDate(date, 'start')}
                renderInput={(params) => <StyledTextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                disabled={!allowEndChange(campaignData?.status as CampaignStatus, campaignData?.schedule?.end)}
                inputFormat={EU_DATE_TIME_12HR}
                label={t('launchWizard.details.endDate')}
                value={dayjs(campaign?.schedule.end).toDate()}
                minDateTime={
                  allowEndChange(campaignData?.status as CampaignStatus, campaignData?.schedule?.end) &&
                  dayjs(campaign?.schedule.start).add(1, 'hours')
                }
                onChange={(date) => handleChangeDate(date, 'end')}
                renderInput={(params) => {
                  return <StyledTextField {...params} fullWidth />
                }}
              />
            </LocalizationProvider>
          </Box>

          <ButtonsWrapper>
            <Button variant="outlined" onClick={onClose}>
              {t('adminDashboard.campaignsTable.editCampaignPopup.cancel')}
            </Button>
            <LoadingButton onClick={handleUpdate} variant="contained" loading={isPending}>
              {t('adminDashboard.campaignsTable.editCampaignPopup.submit')}
            </LoadingButton>
          </ButtonsWrapper>
        </>
      )}
    </PopupLarge>
  )
}

type DetailsBoxProps = {
  type?: 'vertical' | 'horizontal'
  icon: IconDefinition
  label: string
  value: string
}

const DetailsBox: FC<DetailsBoxProps> = ({ type = 'vertical', icon, label, value }) => {
  return (
    <Box display="flex" flexDirection={type === 'vertical' ? 'column' : 'row'} alignItems="center">
      <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
        <FontAwesomeIcon icon={icon as IconProp} fontSize={18} />
        <Typography fontSize="14px" fontWeight="bold">
          {label + ': '}
        </Typography>
      </Box>
      <Typography fontSize="16px">{value}</Typography>
    </Box>
  )
}

const StyledCampaignDetails = styled(Box)(() => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledDetailsSteparator = styled(Box)(() => ({
  minWidth: '1px',
  height: '16px',
  backgroundColor: theme.palette.grey[300],
}))

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root.Mui-disabled': {
    background: theme.palette.grey[200],
  },
}))

export default EditModal
