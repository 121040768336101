import { FC } from 'react'
import { styled } from '@mui/system'
import { useTranslation, Trans } from 'react-i18next'
import { Typography, Box, Divider } from '@mui/material'
import CornerImage from '@/assets/images/logo-clean-blue.svg'
import Logo from '@/assets/images/logo-be-awere-stay-safe.svg'
import SimulationDoor from '@/assets/images/ExpiredCampaign/simulation_door.svg'
import { theme } from '@/theme/theme'

const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -100,
    left: -100,
    width: '300px',
    height: '300px',
    background: `url(${CornerImage}) no-repeat`,
    backgroundSize: 'cover',
    transform: 'rotate(30deg)',
    opacity: 0.2,
    [theme.breakpoints.down('sm')]: {
      top: -50,
      left: -50,
      width: '150px',
      height: '150px',
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -90,
    right: -50,
    width: '300px',
    height: '300px',
    background: `url(${CornerImage}) no-repeat`,
    backgroundSize: 'cover',
    transform: 'rotate(-60deg)',
    opacity: 0.2,
    [theme.breakpoints.down('sm')]: {
      bottom: -45,
      right: -25,
      width: '150px',
      height: '150px',
    },
  },
}))

const RootContainer = styled(Box)(({ theme }) => ({
  width: '45%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const StepsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StepsList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '350px',
})

const LogoImage = styled('img')({
  height: '75px',
  margin: '10px auto',
})

const StepTitle = styled(Typography)({
  fontWeight: 'bold',
})

const ExpiredCampaign: FC = () => {
  const { t } = useTranslation()

  return (
    <BackgroundContainer>
      <RootContainer>
        <LogoImage src={Logo} alt={t('expiredCampaign.altLogo')} />
        <Typography
          variant="h4"
          fontWeight={theme.typography.fontWeightExtraBold}
          sx={{ marginBottom: theme.spacing(1) }}>
          {t('expiredCampaign.title')}
        </Typography>
        <Typography variant="body1" fontWeight={theme.typography.fontWeightRegular} lineHeight={'normal'}>
          {t('expiredCampaign.description')}
        </Typography>
        <Divider sx={{ margin: theme.spacing(2, 0) }} />
        <StepsContainer>
          <Box>
            <Typography fontWeight={theme.typography.fontWeightBold}>{t('expiredCampaign.nextSteps')}</Typography>
            <StepsList>
              <Typography component="span">
                <Trans i18nKey="expiredCampaign.stepOne" components={{ 1: <StepTitle />, 2: <br /> }} />
              </Typography>
              <Typography component="span">
                <Trans i18nKey="expiredCampaign.stepTwo" components={{ 1: <StepTitle /> }} />
              </Typography>
              <Typography component="span">
                <Trans i18nKey="expiredCampaign.stepThree" components={{ 1: <StepTitle /> }} />
              </Typography>
            </StepsList>
          </Box>
          <Box component="img" src={SimulationDoor} height="400px" alt={t('expiredCampaign.altSimulationDoor')} />
        </StepsContainer>
        <Divider sx={{ margin: theme.spacing(2, 0) }} />
        <Typography component="span">
          <Trans i18nKey="expiredCampaign.support" components={{ 1: <a href="mailto:support@cywareness.io" /> }} />
        </Typography>
        <Box sx={{ height: theme.spacing(2) }} />
        <Typography component="span">
          <Trans i18nKey="expiredCampaign.signOff" components={{ 1: <br /> }} />
        </Typography>
      </RootContainer>
    </BackgroundContainer>
  )
}

export default ExpiredCampaign
