import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, TooltipProps, Typography } from '@mui/material'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface InfoTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  title: string | JSX.Element
  fontSize?: number
}
export default function InfoTooltip({ title, fontSize, ...rest }: InfoTooltipProps) {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <Typography
          sx={{
            margin: 0,
            fontSize: 12,
            paddingLeft: 0.6,
          }}>
          {title}
        </Typography>
      }
      {...rest}>
      <FontAwesomeIcon className={classes.icon} icon={faCircleInfo as IconProp} fontSize={fontSize} />
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      padding: theme.spacing(0.2, 0.8),
      color: theme.palette.text.secondary,
    },
  })
)
