import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowsMaximize,
  faArrowsToCircle,
  faSitemap,
  faSlash,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Tooltip, styled } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationListItem } from '@/types/organizations'
import TreeChartSearch from './TreeChartSearch'
import { theme } from '@/theme/theme'

type TreeChartActionsProps = {
  setShowSearchInput: (value: boolean) => void
  zoomIn: () => void
  zoomOut: () => void
  zoomToFit: () => void
  toggleAll: () => void
  collapsedAll: boolean
  recenterAccount: () => void
  showSearchInput: boolean
  searchValue: OrganizationListItem | null
  setSearchValue: (value: OrganizationListItem | null) => void
  handleSearch: (value: OrganizationListItem | null, collapse?: boolean, highlighted?: boolean) => void
}

const ActionButton = styled(Button)({
  fontSize: '24px',
  '&:hover': {
    backgroundColor: theme.palette.blueDianne[50],
  },
  '&:active': {
    backgroundColor: theme.palette.cyan[500],
  },
  borderRadius: 0,
})

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    border: '1px solid gray',
    borderRadius: '0 8px 8px 0',
    backgroundColor: 'white',
  },
})

const TreeChartActions: FC<TreeChartActionsProps> = ({
  setShowSearchInput,
  zoomIn,
  zoomOut,
  zoomToFit,
  toggleAll,
  collapsedAll,
  recenterAccount,
  showSearchInput,
  searchValue,
  setSearchValue,
  handleSearch,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box className={classes.root}>
        <Tooltip title={!showSearchInput ? t('accountCenter.actions.search') : null} placement="right">
          <ActionButton onClick={() => setShowSearchInput(!showSearchInput)}>
            <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={24} />
          </ActionButton>
        </Tooltip>
        {showSearchInput && (
          <TreeChartSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            handleClose={() => setShowSearchInput(false)}
          />
        )}
        <Tooltip title={t('accountCenter.actions.zoomIn')} placement="right">
          <ActionButton onClick={zoomIn}>
            <FontAwesomeIcon icon={faMagnifyingGlassPlus as IconProp} width={24} />
          </ActionButton>
        </Tooltip>
        <Tooltip title={t('accountCenter.actions.zoomToFit')} placement="right">
          <ActionButton onClick={zoomToFit}>
            <FontAwesomeIcon icon={faArrowsMaximize as IconProp} width={24} />
          </ActionButton>
        </Tooltip>
        <Tooltip title={t('accountCenter.actions.zoomOut')} placement="right">
          <ActionButton onClick={zoomOut}>
            <FontAwesomeIcon icon={faMagnifyingGlassMinus as IconProp} width={24} />
          </ActionButton>
        </Tooltip>
        <Tooltip title={t('accountCenter.actions.recenterAccount')} placement="right">
          <ActionButton onClick={recenterAccount}>
            <FontAwesomeIcon icon={faArrowsToCircle as IconProp} width={24} />
          </ActionButton>
        </Tooltip>
        <Tooltip
          title={collapsedAll ? t('accountCenter.actions.expandAll') : t('accountCenter.actions.hideAll')}
          placement="right"
        >
          <ActionButton onClick={toggleAll}>
            <Box className="fa-layers">
              <FontAwesomeIcon icon={faSitemap as IconProp} width={24} />
              {!collapsedAll && <FontAwesomeIcon icon={faSlash as IconProp} width={24} />}
            </Box>
          </ActionButton>
        </Tooltip>
      </Box>
    </>
  )
}

export default TreeChartActions
