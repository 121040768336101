import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { Member } from '../members/get'

export type Group = {
  _id: string
  offices: string[]
  client_id: string
  name: string
  awareness_score: number
  awareness_score_last_30_days: number[]
  user_count: number
  users: string[]
  managers?: string[]
  has_unverified_domain: boolean
  has_inactive_member: boolean
  created_at: string
  disabled?: boolean
  campaign_stats?: {
    [key: string]: {
      passed: number
      failed: number
    }
  }
  members?: {
    items: Member[]
    count: number
  }
  campaign_last_sent_at?: string
}

export const getAllGroups = async () => {
  const url = `${API_URL}/groups/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export const useGroups = () => {
  return useQuery<Group[], ServerError>('groups', getAllGroups)
}
