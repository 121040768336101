import { Box, styled, Button, Typography } from '@mui/material'
import { useAiSimulationContext, VectorType } from '../state'
import NewBadgeAnimation from '@/common/animations/NewBadgeAnimation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faComment } from '@fortawesome/pro-light-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type StyledButtonProps = {
  clicked: boolean
}

const StyledButton = styled(Button)(({ clicked }: StyledButtonProps) => ({
  margin: '2px',
  background: clicked ? '#484B4F' : '#F2F5F7',
  color: clicked ? '#05DBF2' : '#141C26',
  borderRadius: '5px',
  border: 'none',
  width: '100%',
  height: '50px',
  padding: '0px 20px 0px 20px',
  '&:hover': {
    background: clicked ? '#141C26' : '#05DBF2',
  },
  '&.Mui-disabled': {
    color: clicked ? '#05DBF2' : 'rgba(0, 0, 0, 0.26)',
  },
}))
const StyledBox = styled(Box)(() => ({
  mdisplay: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))
const StyledTypography1 = styled(Typography)(({}) => ({
  margin: '8px',
  fontWeight: 'bold',
}))
const StyledTypography2 = styled(Typography)(({}) => ({
  marginBottom: '8px',
  marginTop: '-12px',
  fontSize: '10px',
}))

const VectorToggleButtons = ({ disabled = false }) => {
  const { vectorType, setVectorType } = useAiSimulationContext()
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <StyledButton
          disabled={disabled}
          clicked={vectorType === VectorType.EMAIL}
          onClick={() => {
            setVectorType(VectorType.EMAIL)
          }}
          value={VectorType.EMAIL}
          aria-label={VectorType.EMAIL}
        >
          <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={20} />
          <StyledBox>
            <StyledTypography1>Email</StyledTypography1>
            <StyledTypography2>vector</StyledTypography2>
          </StyledBox>
        </StyledButton>
        <StyledButton
          disabled={disabled}
          clicked={vectorType === VectorType.SMS}
          onClick={() => {
            setVectorType(VectorType.SMS)
          }}
          value={VectorType.SMS}
          aria-label={VectorType.SMS}
        >
          <FontAwesomeIcon icon={faComment as IconProp} fontSize={20} />
          <StyledBox>
            <StyledTypography1>SMS</StyledTypography1>
            <StyledTypography2>vector</StyledTypography2>
          </StyledBox>
        </StyledButton>
        <StyledButton
          disabled={true}
          //TODO: uncomment when activate:
          // disabled={disabled}
          clicked={vectorType === VectorType.WHATSAPP}
          onClick={() => {
            setVectorType(VectorType.WHATSAPP)
          }}
          value={VectorType.WHATSAPP}
          aria-label={VectorType.WHATSAPP}
        >
          <NewBadgeAnimation
            size={75}
            isComingSoon
            //TODO: uncomment when activate:
            // isDark={vectorType !== VectorType.WHATSAPP}
            style={{ position: 'absolute', left: '-23px', bottom: '-3px' }}
          ></NewBadgeAnimation>
          <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={20} />
          <StyledBox>
            <StyledTypography1>Whatsapp</StyledTypography1>
            <StyledTypography2>vector</StyledTypography2>
          </StyledBox>
        </StyledButton>
      </Box>
    </Box>
  )
}
export default VectorToggleButtons
