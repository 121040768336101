import { Box, Typography } from '@mui/material'
import { FC } from 'react'

interface IndicatorProps {
  title: string
  description: string
  isRtl?: boolean
}

const Indicator: FC<IndicatorProps> = ({ title, description, isRtl = false }) => {
  return (
    <Box style={{ direction: isRtl ? 'rtl' : 'ltr' }}>
      <Typography variant="h6" mt={1}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  )
}

export default Indicator
