import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { Box, Button } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Tour from 'reactour'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'

import { useCampaignMicrotraining } from '@/api/campaigns/getMicrotraining'
import { CAMPAIGN_TYPES, EVENT_TYPES, LogEventParams } from '@/api/events/log'
import { usePhishingSimulation } from '@/api/phishing-simulations/get'
import CywarnessHeaderLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import CywarenessLogo from '@/assets/images/logo-dark.svg'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useDeviceDetection, { DeviceType } from '@/common/hooks/useDeviceDetection'
import isLanguageRTL from '@/common/utils/isLanguageRTL'
import { theme } from '@/theme/theme'
import { CampaignMicrotraining, MicrotrainingIndicator } from '@/types/campaigns'
import { PhishingIndicator, PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import useLogEvent from '@/common/hooks/useLogEvent'
import DevicePreview from './components/DevicePreview'
import Header from './components/Header'
import Indicator from './components/Indicator'
import InvalidToken from './components/InvalidToken'

const rtlCache = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
})

const ltrCache = createCache({
  key: 'mui',
})

const buildMicrotraining = (
  simulation: PhishingSimulation,
  language?: string,
  difficulty?: number,
  vector?: string
): CampaignMicrotraining => {
  difficulty = difficulty || simulation.difficulties[0]
  const messageLanguage = language || simulation.languages.message[0]
  const landingPageLanguage = language || simulation.languages.landing_page[0]

  const messageHtml = simulation.html_contents.message
  const landingPageHtml = simulation.html_contents.landing_page

  const buildIndicators: (indicators: PhishingIndicator[]) => MicrotrainingIndicator[] = (indicators) =>
    indicators
      .filter((indicator) => indicator.difficulty === difficulty)
      .map((indicator) => ({
        selector: indicator.selector,
        title: indicator.title[messageLanguage],
        description: indicator.description[messageLanguage],
      }))
  return {
    sender: simulation.sender,
    subject: simulation.subject,
    logo: CywarenessLogo,
    message: {
      html: messageHtml,
      language: messageLanguage,
    },
    landing_page: {
      html: landingPageHtml,
      language: landingPageLanguage,
    },
    indicators: {
      message: buildIndicators(simulation.phishing_indicators?.message),
      landing_page: buildIndicators(simulation.phishing_indicators?.landing_page),
    },
    token: '',
    vector: vector,
  }
}

interface MicrotrainingProps {
  isPreview?: boolean
}

const Microtraining: FC<MicrotrainingProps> = ({ isPreview = false }) => {
  const theme = useTheme()
  const { log: logEvent } = useLogEvent()
  const { t, i18n } = useTranslation()
  const { simulationId, token } = useParams()
  const [isTourOpen, setIsTourOpen] = useState(true)
  const deviceType = useDeviceDetection()

  const {
    data: simulation,
    isPending: isSimulationPending,
    isError: isSimulationError,
  } = usePhishingSimulation(simulationId)

  const {
    data: microtraining,
    isPending: isMicrotrainingPending,
    isError: isMicrotrainingError,
  } = useCampaignMicrotraining(token)

  const loading = isSimulationPending && isMicrotrainingPending
  const error = isSimulationError && isMicrotrainingError
  const attackVector = simulation?.vectors[0] || microtraining?.vector
  const data = useMemo<CampaignMicrotraining | undefined>(
    () => (isPreview && simulation ? buildMicrotraining(simulation) : microtraining),
    [simulation, microtraining]
  )

  useEffect(() => {
    if (data?.message.language) {
      i18n.changeLanguage(data.message.language)
    }
  }, [data?.message.language, i18n])

  const logCampaignEvent =
    isPreview || !data?.token
      ? () => {}
      : (params: Omit<LogEventParams, 'campaignToken'>) => logEvent({ ...params, campaignToken: data.token })

  const messageMicrotrainingSteps = useMemo(
    () => [
      {
        selector: '#title',
        content: (
          <Indicator
            title={t('feedbackPage.welcomeTitle')}
            description={t('feedbackPage.welcomeMessage')}
            isRtl={isLanguageRTL(data?.message.language)}
          />
        ),
      },
      ...(data?.indicators.message || []).map((indicator) => ({
        selector: indicator.selector,
        content: ({ inDOM }: { inDOM: boolean }) => {
          return (
            <Indicator
              title={(inDOM && indicator.title) || ''}
              description={(inDOM && indicator.description) || ''}
              isRtl={isLanguageRTL(data?.message.language)}
            />
          )
        },
      })),
      {
        selector: '#title',
        content: (
          <Indicator
            title={t('feedbackPage.finalTitle')}
            description={t('feedbackPage.finalMessage')}
            isRtl={isLanguageRTL(data?.message.language)}
          />
        ),
      },
    ],
    [data, t]
  )

  const handleOpenTraining = () => {
    setIsTourOpen(true)
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_TIPS_CLICKED,
      campaignType: CAMPAIGN_TYPES.EMAIL,
    })
  }

  const handleTrainingStepChange = (currentStep: number) => {
    var stepsLength = messageMicrotrainingSteps.length
    var currentPage = currentStep + 1
    var pageIteration = {
      current_page: currentPage,
      total_pages: stepsLength,
    }

    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_PAGE_ITERATION,
      campaignType: CAMPAIGN_TYPES.EMAIL,
      additionalData: { page_iteration: pageIteration },
    })
  }

  const handleCloseTraining = () => {
    setIsTourOpen(false)
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_CLOSED,
      campaignType: CAMPAIGN_TYPES.EMAIL,
    })
  }

  const handleFinishTraining = () => {
    setIsTourOpen(false)
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_COMPLETED,
      campaignType: CAMPAIGN_TYPES.EMAIL,
    })
  }
  if (loading) return <LoadingContainer />
  if (error || !data) return <InvalidToken />

  return (
    <Box sx={{ height: '100%' }}>
      <Header logo={CywarnessHeaderLogo} />
      <Box sx={{ width: '90%', margin: 'auto' }}>
        <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
          <Box sx={{ width: '100%', margin: 'auto', textAlign: 'right' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleOpenTraining}
              sx={{ marginBottom: theme.spacing(2), color: theme.palette.black }}>
              {t('feedbackPage.showTips')}
            </Button>
          </Box>
          <DevicePreview
            deviceType={deviceType as DeviceType}
            attackVector={attackVector as PhishingSimulationVector}
            data={data}
            isRtl={isLanguageRTL(data?.message.language)}
          />
          <CacheProvider value={isLanguageRTL(data?.message.language) ? rtlCache : ltrCache}>
            <StyledRtlLtrTour
              isRtl={isLanguageRTL(data?.message.language)}
              accentColor={theme.palette.cyan[500]}
              rounded={8}
              steps={messageMicrotrainingSteps}
              isOpen={isTourOpen}
              getCurrentStep={handleTrainingStepChange}
              onRequestClose={handleCloseTraining}
              onAfterOpen={(target) => disableBodyScroll(target)}
              onBeforeClose={(target) => enableBodyScroll(target)}
              lastStepNextButton={
                <Button
                  variant="contained"
                  onClick={handleFinishTraining}
                  sx={{ transform: isLanguageRTL(data?.message.language) ? 'rotate(180deg)' : '' }}>
                  {t('feedbackPage.finish')}
                </Button>
              }
              inViewThreshold={600}
              scrollOffset={1}
              scrollDuration={300}
            />
          </CacheProvider>
        </Box>
      </Box>
    </Box>
  )
}

const StyledRtlLtrTour = styled(Tour)(({ isRtl }: { isRtl: boolean }) => ({
  ...(isRtl && {
    '[data-tour-elem="controls"]': {
      direction: 'rtl',
      '[data-tour-elem="navigation"]': {
        transform: 'rotate(180deg)',
      },

      '[data-tour-elem="right-arrow"]': {
        transform: 'rotate(180deg)',
      },
      '[data-tour-elem="left-arrow"]': {
        transform: 'rotate(180deg)',
      },
      display: 'flex',
      gap: theme.spacing(3),
      flexDirection: 'row-reverse',
    },
  }),
}))
export default Microtraining
